import React, { lazy, Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { LoadingWheel } from 'components/LoadingWheel';
import { GlobalStyle } from 'theme/global-style';
import { ThemeProvider } from 'theme/ThemeProvider';
import { ShopDataContextProvider } from 'contexts/ShopData';
import { PaymentContextProvider } from 'contexts/Payment';
import { ModalContextProvider } from 'contexts/Modal';
import { FeaturesContextProvider } from 'contexts/Features';

const Home = lazy(() => import('pages/Home'));
const Menu = lazy(() => import('pages/Menu'));
const Payment = lazy(() => import('pages/Payment'));
const QrCodeRedirection = lazy(() => import('pages/QrCodeRedirection'));

export const App = () => (
  <HelmetProvider>
    <BrowserRouter>
      <GlobalStyle />

      <ShopDataContextProvider>
        <ThemeProvider />

        <PaymentContextProvider>
          <ModalContextProvider>
            <FeaturesContextProvider>
              <Suspense fallback={<LoadingWheel />}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/qrcode/:shopAlias/:tableNumber" element={<QrCodeRedirection />} />
                  <Route path="/qrcode/:shopAlias" element={<QrCodeRedirection />} />

                  {/* FIXME: We should be able to share the Header component between the below pages in one place */}
                  <Route path=":shopId/:tableNumber" element={<Menu />} />
                  <Route path=":shopId" element={<Menu />} />
                  <Route path="/payment/:shopId/:tableNumber" element={<Payment />} />

                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Suspense>
            </FeaturesContextProvider>
          </ModalContextProvider>
        </PaymentContextProvider>
      </ShopDataContextProvider>
    </BrowserRouter>
  </HelmetProvider>
);
