import convert from 'color-convert';
import { css } from 'styled-components';
import { Color, DefaultColor } from 'theme';

export const centerChildren = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * generate rgba color
 * @param color Hex Color (not variable color)
 * @param opacity alpha - color's transparency (0-1)
 * @returns color with opacity
 */
export const withOpacity = (color: string | Color | DefaultColor, opacity: number): string => {
  const [red, green, blue] = convert.hex.rgb(color);

  return `rgba(${red},${green},${blue}, ${opacity})`;
};

/**
 * Convert a size from pixels to root em
 * @param sizeInPixels Multiple of 8 ideally
 * @returns size in rem
 */
export const pxToRem = (sizeInPixels: number): string => {
  const sizeInRem = sizeInPixels / 8;
  return `${sizeInRem}rem`;
};
