import { useContext } from 'react';
import { PaymentContext } from 'contexts/Payment';

export const usePaymentContext = () => {
  const {
    currentStepState,
    selectedProductsState,
    customerEmailState,
    arbitraryAmountState,
    orderState,
  } = useContext(PaymentContext);

  const [currentStep, setCurrentStep] = currentStepState;
  const [selectedProducts, setSelectedProducts] = selectedProductsState;
  const [customerEmail, setCustomerEmail] = customerEmailState;
  const [arbitraryAmount, setArbitraryAmount] = arbitraryAmountState;
  const [order, setOrder] = orderState;

  const allOrderedProducts = order?.orderedProducts || [];

  const orderedProducts = {
    all: allOrderedProducts,
    unPaid: allOrderedProducts.filter((product) => !product.isPaid),
  };

  return {
    currentStep,
    setCurrentStep,

    selectedProducts,
    setSelectedProducts,

    customerEmail,
    setCustomerEmail,

    order,
    setOrder,

    arbitraryAmount,
    setArbitraryAmount,

    orderedProducts,
  };
};
