import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderedProductList } from 'components/Payment/OrderedProductList';
import { StepButton } from 'pages/Payment/styles';
import { PaymentStep } from 'pages/Payment/content';
import { formatPrice } from 'utils/price';
import { usePaymentContext } from 'hooks/usePaymentContext';
import { InputBox, TotalBox, YourPaymentBox } from './styles';

export const ArbitraryStep = () => {
  const { t } = useTranslation();
  const { setCurrentStep, order, setArbitraryAmount } = usePaymentContext();

  const [amountToPay, setAmountToPay] = useState(0);

  if (!order) return null;

  const amountToPayInCents = amountToPay * 100;

  const isButtonDisabled = amountToPay <= 0 || amountToPayInCents > order.leftToPay;

  const onButtonClick = () => {
    setArbitraryAmount(amountToPayInCents);
    setCurrentStep(PaymentStep.Contact);
  };

  return (
    <>
      <TotalBox isTotalLeft>
        <p>{t('leftToPay')}</p>
        <p>{formatPrice(order?.leftToPay - amountToPayInCents)}</p>
      </TotalBox>

      <OrderedProductList mergeProducts paidProducts="muted" />

      <InputBox>
        <h1>{t('ISettle')}</h1>
        <input
          type="number"
          value={amountToPay === 0 ? '' : amountToPay}
          onChange={(e) =>
            setAmountToPay(Number.isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber)
          }
        />
        €
      </InputBox>

      <TotalBox>
        <p>{t('orderTotal')}</p>
        <p>{formatPrice(order?.totalPrice)}</p>
      </TotalBox>

      <YourPaymentBox>
        <p>{t('yourPayment')}</p>
        <p>{formatPrice(amountToPayInCents)}</p>
      </YourPaymentBox>

      <StepButton disabled={isButtonDisabled} onClick={onButtonClick}>
        {t('pay')}
        {!isButtonDisabled && <span> {formatPrice(amountToPayInCents)}</span>}
      </StepButton>
    </>
  );
};
