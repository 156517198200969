import React from 'react';
import { Color } from 'theme';
import { Loader } from './styles';

type Props = {
  diameter?: number;
  colors?: {
    primary: Color;
    secondary: Color;
  };
};

const defaultDiameter = 64;
const defaultColors = { primary: Color.Primary, secondary: Color.PrimaryLighter };

export const LoadingWheel = (props: Props) => {
  const { diameter = defaultDiameter, colors = defaultColors } = props;

  return <Loader diameter={diameter} colors={colors} />;
};

LoadingWheel.defaultProps = {
  diameter: defaultDiameter,
  colors: defaultColors,
};
