import React, { createContext, useCallback, useState } from 'react';

export enum ModalType {
  None,
  Categories,
  Product,
  Cart,
  OrderSuccess,
  CGU,
  Invoice,
  NeedPayment,
  NeedHelp,
}

type ModalContextType = {
  displayedModal: ModalType;
  toggleModal: (modal: ModalType) => void;
};

const defaultContextValue: ModalContextType = {
  displayedModal: ModalType.None,
  toggleModal: () => null,
};

export const ModalContext = createContext<ModalContextType>(defaultContextValue);

export const ModalContextProvider = ({ children }: { children: JSX.Element }) => {
  const [displayedModal, setDisplayedModal] = useState(defaultContextValue.displayedModal);

  const toggleModal = useCallback(
    (modal: ModalType) =>
      displayedModal === modal
        ? setDisplayedModal(defaultContextValue.displayedModal)
        : setDisplayedModal(modal),
    [displayedModal]
  );

  const ModalContextValue: ModalContextType = {
    displayedModal,
    toggleModal,
  };

  return <ModalContext.Provider value={ModalContextValue}>{children}</ModalContext.Provider>;
};
