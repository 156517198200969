import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';

import { LoadingWheel } from 'components/LoadingWheel';
import { usePaymentContext } from 'hooks/usePaymentContext';
import { useApi, Method } from 'services/api';
import { PaymentStep } from 'pages/Payment/content';
import { StepButton } from 'pages/Payment/styles';
import { formatPrice, getOrderedProductsPrice } from 'utils/price';

import { Component, ErrorText } from './styles';
import { cardElementOptions } from './content';

export const CreditCardStep = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [checkoutError, setCheckoutError] = useState<string>();

  const { setCurrentStep, customerEmail, selectedProducts, order, arbitraryAmount } =
    usePaymentContext();

  const { shopId } = useParams();

  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = (
    ev:
      | StripeCardCvcElementChangeEvent
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
  ) => setCheckoutError(ev.error?.message);

  const isArbitraryPayment = arbitraryAmount !== undefined;

  const {
    isLoading,
    data: clientSecret,
    status,
    sendRequest,
    // error, TODO: handle error here
  } = useApi(
    isArbitraryPayment
      ? `shop/${shopId}/order/${order?.id}/arbitrary-payment`
      : `shop/${shopId}/order/${order?.id}/payment`,
    Method.POST
  );

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await sendRequest(
      isArbitraryPayment
        ? {
            email: customerEmail,
            paymentAmount: arbitraryAmount,
          }
        : {
            email: customerEmail,
            productsToPayId: selectedProducts.map((product) => product.id),
          }
    );
  };

  useEffect(() => {
    const checkout = async () => {
      setIsProcessing(true);
      const cardElement = elements?.getElement(CardNumberElement);
      const paymentMethodReq = await stripe?.createPaymentMethod({
        type: 'card',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        card: cardElement!,
        billing_details: { email: customerEmail },
      });
      if (paymentMethodReq?.error) {
        setCheckoutError(paymentMethodReq?.error?.message);
        setIsProcessing(false);
        return;
      }
      const confirmCardPayment = await stripe?.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq?.paymentMethod?.id,
      });
      setIsProcessing(false);
      // payment success
      if (confirmCardPayment?.paymentIntent?.status === 'succeeded') {
        setCurrentStep(PaymentStep.Complete);
      } else {
        setCurrentStep(PaymentStep.Error);
      }
    };

    if (status === 202) checkout();
  }, [status]);

  const priceToPay = arbitraryAmount || getOrderedProductsPrice(selectedProducts);

  return (
    <Component onSubmit={onSubmit}>
      <h1>{t('inputPaymentData')}</h1>

      <h3>{t('cardData')}</h3>

      <fieldset id="card-infos">
        <CardNumberElement options={cardElementOptions} onChange={handleChange} className="row" />
        <CardExpiryElement options={cardElementOptions} onChange={handleChange} />
        <CardCvcElement options={cardElementOptions} onChange={handleChange} />
      </fieldset>

      {checkoutError && <ErrorText>{checkoutError}</ErrorText>}

      <StepButton id="payment-validation" type="submit" disabled={isLoading || isProcessing}>
        {isLoading || isProcessing ? (
          <LoadingWheel diameter={20} />
        ) : (
          <>
            {t('pay')}
            <span> {formatPrice(priceToPay)}</span>
          </>
        )}
      </StepButton>
    </Component>
  );
};
