import React from 'react';
import { CloseCrossWrapper, CloseCrossIcon } from './styles';

type CloseCrossProps = {
  onClick: () => void;
};

export const CloseCross = ({ onClick }: CloseCrossProps) => {
  return (
    <CloseCrossWrapper onClick={onClick}>
      <CloseCrossIcon />
    </CloseCrossWrapper>
  );
};
