import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StepButton } from 'pages/Payment/styles';
import { PaymentStep } from 'pages/Payment/content';
import { usePaymentContext } from 'hooks/usePaymentContext';
import { formatPrice } from 'utils/price';
import { TotalBox, YourPaymentBox } from '../ArbitraryStep/styles';
import { ButtonsBox, Component } from './styles';

export const SplitBillStep = () => {
  const { t } = useTranslation();
  const { order, setCurrentStep, setArbitraryAmount } = usePaymentContext();

  const [peopleAtTheTable, setPeopleAtTheTable] = useState(3);
  const [payFor, setPayFor] = useState(1);

  if (!order) return null;

  const amountToPay = (order.leftToPay / peopleAtTheTable) * payFor;

  const onButtonClick = () => {
    setArbitraryAmount(Math.floor(amountToPay));
    setCurrentStep(PaymentStep.Contact);
  };

  const onPayForClick = (action: 'inc' | 'decr') => {
    if (action === 'decr') {
      if (payFor <= 1) return null;

      return setPayFor((prev) => prev - 1);
    }

    if (payFor < peopleAtTheTable) return setPayFor((prev) => prev + 1);

    return null;
  };

  const editPeopleAtTheTable = (action: 'inc' | 'decr') => {
    if (action === 'decr') {
      if (peopleAtTheTable <= 1) return null;

      if (payFor > peopleAtTheTable - 1) setPayFor(peopleAtTheTable - 1);

      return setPeopleAtTheTable((prev) => prev - 1);
    }

    return setPeopleAtTheTable((prev) => prev + 1);
  };

  return (
    <Component>
      <h1>{t('peopleAtTheTable')}</h1>
      <ButtonsBox>
        <button type="button" onClick={() => editPeopleAtTheTable('decr')}>
          -
        </button>
        <h2>{peopleAtTheTable}</h2>
        <button type="button" onClick={() => editPeopleAtTheTable('inc')}>
          +
        </button>
      </ButtonsBox>

      <h1>{t('payFor')}</h1>
      <ButtonsBox>
        <button type="button" onClick={() => onPayForClick('decr')}>
          -
        </button>
        <h2>{payFor}</h2>
        <button type="button" onClick={() => onPayForClick('inc')}>
          +
        </button>
      </ButtonsBox>

      <TotalBox>
        <p>{t('orderTotal')}</p>
        <p>{formatPrice(order.leftToPay)}</p>
      </TotalBox>

      <YourPaymentBox>
        <p>{t('yourPayment')}</p>
        <p>{formatPrice(amountToPay)}</p>
      </YourPaymentBox>

      <StepButton onClick={onButtonClick}>
        {t('pay')}
        <span> {formatPrice(amountToPay)}</span>
      </StepButton>
    </Component>
  );
};
