import React from 'react';
import { ContactStep } from 'components/Payment/ContactStep';
import { MeanChoiceStep } from 'components/Payment/MeanChoiceStep';
import { PaymentMethodStep } from 'components/Payment/PaymentMethodStep';
import { SelectProductStep } from 'components/Payment/SelectProductStep';
import { CreditCardStep } from 'components/Payment/CreditCardStep';
import { CompleteStep } from 'components/Payment/CompleteStep';
import { ErrorStep } from 'components/Payment/ErrorStep';
import { ArbitraryStep } from 'components/Payment/ArbitraryStep';
import { SplitBillStep } from 'components/Payment/SplitBillStep';

export enum PaymentStep {
  Method = 'method',
  SelectProduct = 'selectProduct',
  Arbitrary = 'arbitrary',
  Contact = 'contact',
  MeanChoice = 'meanChoice',
  CreditCard = 'creditCard',
  Complete = 'complete',
  Error = 'error',
  SplitBill = 'splitBill',
}

export const PAYMENT_STEP: { [key in PaymentStep]: JSX.Element } = {
  method: <PaymentMethodStep />,
  selectProduct: <SelectProductStep />,
  arbitrary: <ArbitraryStep />,
  contact: <ContactStep />,
  meanChoice: <MeanChoiceStep />,
  creditCard: <CreditCardStep />,
  complete: <CompleteStep />,
  error: <ErrorStep />,
  splitBill: <SplitBillStep />,
};

export const getStepProgression = (currentStep: PaymentStep) => {
  switch (currentStep) {
    case PaymentStep.Method:
      return '10%';

    case PaymentStep.SelectProduct:
    case PaymentStep.Arbitrary:
    case PaymentStep.SplitBill:
      return '20%';

    case PaymentStep.Contact:
      return '40%';

    case PaymentStep.MeanChoice:
      return '60%';

    case PaymentStep.CreditCard:
      return '80%';

    case PaymentStep.Complete:
    case PaymentStep.Error:
      return '100%';

    default:
      return '0%';
  }
};

export const getRecapSentenceVisibility = (currentStep: PaymentStep) =>
  currentStep !== PaymentStep.Method &&
  currentStep !== PaymentStep.Error &&
  currentStep !== PaymentStep.Arbitrary &&
  currentStep !== PaymentStep.SplitBill;
