import React, { useContext } from 'react';
import { StepButton } from 'pages/Payment/styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IorderLogo } from 'assets/logo-iorder.svg';
import { CommentLink } from 'components/ShopInformationModal/styles';
import { ShopDataContext } from 'contexts/ShopData';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Footer } from './styles';

export const CompleteStep = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { shopId, tableNumber } = useParams();

  const { shopInformation } = useContext(ShopDataContext);

  const goToHome = () => {
    navigate(`/${shopId}/${tableNumber}`);
  };

  return (
    <>
      <Container id="payment-validation">
        {t('paymentConfirmation')}

        <CommentLink href={shopInformation.googleLink} target="_blank" rel="noreferrer">
          {t('leaveComment')}
        </CommentLink>
      </Container>

      <StepButton id="back-to-home" onClick={goToHome} center>
        {t('backToHome')}
      </StepButton>

      <Footer>
        <p>{t('completeStepFooter')}</p>
        <IorderLogo width="120" height="auto" />
      </Footer>
    </>
  );
};
