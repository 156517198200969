import React, { createContext, Dispatch, SetStateAction, useState } from 'react';

export interface FeaturesContextType {
  isOrderEnabled: boolean;
  setIsOrderEnabled: Dispatch<SetStateAction<boolean>>;
}

const defaultContextValue: FeaturesContextType = {
  isOrderEnabled: false,
  setIsOrderEnabled: () => null,
};

export const FeaturesContext = createContext<FeaturesContextType>(defaultContextValue);

type FeaturesContextProviderProps = { children: JSX.Element };

export const FeaturesContextProvider = (props: FeaturesContextProviderProps) => {
  const [isOrderEnabled, setIsOrderEnabled] = useState(defaultContextValue.isOrderEnabled);

  const FeaturesContextValue: FeaturesContextType = {
    isOrderEnabled,
    setIsOrderEnabled,
  };

  return (
    <FeaturesContext.Provider value={FeaturesContextValue}>
      {props.children}
    </FeaturesContext.Provider>
  );
};
