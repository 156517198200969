import { Color, Font, FontSize, FontWeight } from 'theme';
import styled from 'styled-components';
import { pxToRem, withOpacity } from 'utils/style-utils';
import { StripeElementStyle } from '@stripe/stripe-js';

export const iframeStyles: StripeElementStyle = {
  base: {
    fontFamily: Font.Primary,
    color: Color.UtilityDark,
    '::placeholder': {
      color: Color.PrimaryLighter,
    },
  },
  invalid: {
    color: Color.Error,
    iconColor: Color.Error,
  },
};

export const Component = styled.form`
  h1 {
    font-family: ${Font.Secondary};
    font-size: ${FontSize.Big};
    font-weight: ${FontWeight.Light};
    margin-bottom: ${pxToRem(24)};
  }

  h3 {
    color: ${Color.Primary};
    font-size: ${pxToRem(18)};
    margin-bottom: ${pxToRem(8)};
  }

  p {
    color: ${withOpacity(Color.UtilityDark, 0.5)};
    font-size: ${pxToRem(14)};
    margin-bottom: ${pxToRem(8)};
  }

  fieldset {
    border: 1px solid ${withOpacity(Color.UtilityDark, 0.5)};
    border-radius: 5px;
    padding: ${pxToRem(24)} ${pxToRem(16)};
    margin: ${pxToRem(16)} 0;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${pxToRem(24)};

    div {
      padding-bottom: ${pxToRem(4)};
      border-bottom: 1px solid ${withOpacity(Color.UtilityDark, 0.5)};
    }

    .row {
      grid-column: span 2;
    }
  }
`;

export const ErrorText = styled.div`
  color: ${withOpacity(Color.Error, 0.5)};
  font-size: ${pxToRem(14)};
`;
