import { OrderedProduct } from 'utils/dto';

export enum BorderType {
  None,
  Primary,
  Secondary,
}

export const getBottomBorder = (
  product: OrderedProduct,
  products: OrderedProduct[],
  index: number
) => {
  const isNextProductCategoryDifferent = product.category !== products[index + 1]?.category;

  const isLastChild = product === products[products.length - 1];

  if (isLastChild) return BorderType.None;
  if (isNextProductCategoryDifferent) return BorderType.Secondary;
  return BorderType.Primary;
};

export const sortProductsFunction = (a: OrderedProduct, b: OrderedProduct) => {
  // order by category
  if (a.category > b.category) return 1;
  if (a.category < b.category) return -1;

  // then by product id
  if (a.id < b.id) return -1;
  if (a.id > b.id) return 1;
  return 0;
};
