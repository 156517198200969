import styled from 'styled-components';
import { Color } from 'theme/colors';
import { ReactComponent as CloseSvg } from 'assets/ExitCross.svg';
import { pxToRem } from 'utils/style-utils';
import { hoverTransition } from 'theme';

export const CloseCrossIcon = styled(CloseSvg)`
  width: ${pxToRem(20)};
  height: ${pxToRem(24)};
  fill: ${Color.Font};
  ${hoverTransition}
`;

export const CloseCrossWrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover ${CloseCrossIcon}, &:focus ${CloseCrossIcon} {
    fill: ${Color.Secondary};
  }
`;
