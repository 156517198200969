import styled from 'styled-components';
import { Color, FontSize } from 'theme';
import { pxToRem, withOpacity } from 'utils/style-utils';

export const Container = styled.form`
  display: flex;
  flex-direction: column;

  font-size: ${pxToRem(14)};
`;

export const TextInputContainer = styled.label<{ error: boolean }>`
  display: flex;
  flex-direction: column;

  input {
    all: unset;
    border-bottom: 2px solid ${(props) => (props.error ? Color.Error : Color.PrimaryLighter)};
    padding-bottom: ${pxToRem(4)};
    font-size: ${FontSize.Regular};
  }

  span {
    margin: ${pxToRem(8)} 0 ${pxToRem(22)};
    color: ${(props) =>
      props.error ? withOpacity(Color.Error, 0.7) : withOpacity(Color.UtilityDark, 0.5)};
  }
`;

export const CheckboxInputContainer = styled.label<{ error: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.error ? withOpacity(Color.Error, 0.7) : withOpacity(Color.UtilityDark, 0.5)};

  input {
    margin-right: ${pxToRem(8)};
  }
`;

export const TermsLink = styled.div`
  text-decoration: underline;
`;
