import styled from 'styled-components';
import { Color, FontSize } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const Container = styled.div`
  font-size: ${FontSize.Big};
  text-align: center;

  button {
    /* OverWriting StepButton styles  */
    background: transparent;
    border-radius: ${pxToRem(5)};

    border: 2px solid ${Color.Primary};
    color: ${Color.Primary};

    margin: ${pxToRem(32)} auto 0;

    position: relative;
    left: 0;
    bottom: 0;
  }
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${pxToRem(16)} 0;

  p {
    color: ${Color.FontLighter};
    font-size: ${FontSize.Small};
  }

  svg {
    margin-top: ${pxToRem(8)};
  }
`;
