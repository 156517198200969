import styled from 'styled-components';
import { pxToRem } from 'utils/style-utils';
import { Color } from './colors';
import { Font, FontSize, FontWeight } from './fonts';

type CtaProps = {
  pillShape?: boolean;
  spaceBetween?: boolean;
};

export const Cta = styled.button<CtaProps>`
  background: ${Color.Primary};
  padding: ${pxToRem(16)};
  width: 100%;
  border-radius: ${(props) => (props.pillShape ? '999px' : pxToRem(5))};

  color: ${Color.UtilityBright};
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.Big};

  display: flex;
  justify-content: ${(props) => (props.spaceBetween ? 'space-between' : 'center')};
  align-items: center;
  gap: ${pxToRem(16)};

  /* PRICE */
  span {
    color: ${Color.UtilityBright};
    font-family: ${Font.Secondary};
    font-weight: ${FontWeight.Light};
    font-size: ${FontSize.Title};
    white-space: nowrap;
  }
`;

export const IncrementButton = styled.button`
  background: ${Color.Secondary};
  border-radius: 50%;
  height: ${pxToRem(20)};
  width: ${pxToRem(20)};
  min-height: ${pxToRem(20)};
  min-width: ${pxToRem(20)};
  font-size: ${pxToRem(16)};
  margin-left: ${pxToRem(12)};

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${Font.Primary};
  color: ${Color.UtilityBright};
  font-weight: ${FontWeight.Bold};
`;
