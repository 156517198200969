export enum DefaultFont {
  Primary = 'Roboto, Numans, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Helvetica Neue", sans-serif',
  Secondary = '"Roboto Slab", Roboto, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Helvetica Neue", sans-serif',
}

/*
  AVALIABLE FONTS (from google link):
    Roboto
    Roboto Slab
    Lato
    Yanone Kaffeesatz
    Open Sans
*/

export enum Font {
  Primary = 'var(--primaryFont)',
  Secondary = 'var(--secondaryFont)',
}

export enum FontWeight {
  Light = 300,
  Regular = 400,
  Bold = 700,
}

export enum FontSize {
  /** 24px */
  Title = '3rem',
  /** 20px */
  Big = '2.5rem',
  /** 16px */
  Regular = '2rem',
  /** 12px */
  Small = '1.7rem',
}
