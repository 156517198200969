import React from 'react';

import { usePaymentContext } from 'hooks/usePaymentContext';
import { OrderedProduct } from 'utils/dto';
import { formatPrice } from 'utils/price';
import { mergeOrderedProducts } from 'utils/products';
import { IncrementButton } from 'theme/globals';

import { getBottomBorder, sortProductsFunction } from './content';
import { Product, ProductInformation, Quantity, OptionBox, Option, Price } from './styles';

type Props = {
  mergeProducts?: boolean;
  addButton?: boolean;
  paidProducts: 'hidden' | 'muted';
};

export const OrderedProductList = ({ mergeProducts, addButton, paidProducts }: Props) => {
  const { order, setSelectedProducts, selectedProducts, orderedProducts } = usePaymentContext();

  if (!order) return null;

  const getProducts = (): OrderedProduct[] => {
    const filteredProducts =
      paidProducts === 'hidden' ? orderedProducts.unPaid : orderedProducts.all;

    const groupedProducts = mergeProducts
      ? mergeOrderedProducts(filteredProducts)
      : filteredProducts;

    const sortedProducts = groupedProducts.sort((a, b) => sortProductsFunction(a, b));

    return sortedProducts;
  };

  const products = getProducts();

  const handleAddButton = (product: OrderedProduct) => {
    if (!addButton) return null;

    if (!selectedProducts.includes(product))
      return setSelectedProducts((prev) => [...prev, product]);

    const newSelectedProducts = selectedProducts.filter((item) => item !== product);
    return setSelectedProducts(newSelectedProducts);
  };

  return (
    <div>
      {products.map((product, index) => {
        const outputBorderType = getBottomBorder(product, products, index);

        const isProductSelected = selectedProducts.includes(product);

        return (
          <Product
            data-cy={isProductSelected ? 'selected-ordered-product' : 'ordered-product'}
            key={index}
            bottomBorder={outputBorderType}
            isSelected={!!addButton && isProductSelected}
            isClickable={!!addButton}
            isMuted={paidProducts === 'muted' && product.isPaid}
            onClick={() => handleAddButton(product)}
            type="button"
          >
            <ProductInformation data-cy="product-infos">
              {product.name}
              {product.quantity > 1 && <Quantity>(x{product.quantity})</Quantity>}
              {product.orderedOptions && product.orderedOptions.length > 0 && (
                <OptionBox>
                  {product.orderedOptions?.map((option) => (
                    <Option key={option.id}>{option.name}</Option>
                  ))}
                </OptionBox>
              )}
            </ProductInformation>

            <Price>
              {formatPrice(product.price * (product.quantity || 1))}
              {addButton && <IncrementButton>{isProductSelected ? '-' : '+'}</IncrementButton>}
            </Price>
          </Product>
        );
      })}
    </div>
  );
};

OrderedProductList.defaultProps = {
  mergeProducts: false,
  addButton: false,
};
