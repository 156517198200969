import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { usePersistentState } from 'hooks/usePersistentState';
import { Product, ShopInformation } from 'utils/dto';

type ShopDataContextType = {
  products: Product[];
  setProducts: Dispatch<SetStateAction<Product[]>>;
  shopInformation: ShopInformation;
  setShopInformation: Dispatch<SetStateAction<ShopInformation>>;
};

const defaultContextValue: ShopDataContextType = {
  products: [],
  setProducts: () => null,
  shopInformation: {
    id: '',
    name: '',
    logoUrl: '',
    phone: '',
    email: '',
    website: '',
    address: '',
    facebookLink: '',
    twitterLink: '',
    instagramLink: '',
    googleLink: '',
    rights: [],
    theme: {
      primaryColor: '',
      primaryLighterColor: '',
      primaryFontColor: '',
      primaryFontLighterColor: '',
      secondaryColor: '',
      primaryFont: '',
      secondaryFont: '',
      headerColor: '',
      headerIconsColor: '',
    },
    openHours: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    stripePublicKey: '',
  },
  setShopInformation: () => null,
};

export enum Rights {
  Order = 'order',
  Pay = 'pay',
}

export const ShopDataContext = createContext<ShopDataContextType>(defaultContextValue);

type ShopDataContextProviderProps = { children: ReactNode };

export const ShopDataContextProvider = (props: ShopDataContextProviderProps) => {
  const splitUrl = window.location.href.split('/');
  const shopId = splitUrl[splitUrl.length - 2];

  const [products, setProducts] = useState<Product[]>(defaultContextValue.products);
  const [shopInformation, setShopInformation] = usePersistentState<ShopInformation>(
    `shop-${shopId}`,
    defaultContextValue.shopInformation
  );

  const ShopDataContextValue: ShopDataContextType = {
    products,
    setProducts,
    shopInformation,
    setShopInformation,
  };

  return (
    <ShopDataContext.Provider value={ShopDataContextValue}>
      {props.children}
    </ShopDataContext.Provider>
  );
};
