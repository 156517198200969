import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useApi, Method } from 'services/api';
import { usePaymentContext } from 'hooks/usePaymentContext';
import { PaymentStep } from 'pages/Payment/content';
import { Cta } from 'theme/globals';
import { formatPrice } from 'utils/price';
import { ModalContext, ModalType } from 'contexts/Modal';
import { Component, Header } from './styles';

export const PaymentMethodStep = () => {
  const { setCurrentStep, setArbitraryAmount, order } = usePaymentContext();
  const { toggleModal } = useContext(ModalContext);

  const { t } = useTranslation();

  const { shopId } = useParams();

  const { sendRequest, resetError } = useApi(
    `shop/${shopId}/order/${order?.id}/needs-payment`,
    Method.POST
  );

  if (!order) return null;

  const onButtonClick = async (
    button: 'cash' | 'select-products' | 'all' | 'arbitrary' | 'splitBill'
  ) => {
    if (button === 'all') {
      setArbitraryAmount(order.leftToPay);
      return setCurrentStep(PaymentStep.Contact);
    }

    if (button === 'cash') {
      resetError();
      await sendRequest();
      toggleModal(ModalType.NeedPayment);

      return null;
    }

    if (button === 'arbitrary') return setCurrentStep(PaymentStep.Arbitrary);

    if (button === 'splitBill') return setCurrentStep(PaymentStep.SplitBill);

    return setCurrentStep(PaymentStep.SelectProduct); // button === 'select-products'
  };

  return (
    <>
      <Component>
        <Header>
          {t('orderTotal')}
          <div>{formatPrice(order.leftToPay)}</div>
        </Header>

        <Cta spaceBetween onClick={() => onButtonClick('cash')}>
          {t('payInCash')}
        </Cta>

        <Cta spaceBetween onClick={() => onButtonClick('splitBill')}>
          {t('splitBill')}
        </Cta>

        <Cta
          spaceBetween
          onClick={() => onButtonClick('select-products')}
          disabled={order.hasPaidArbitrary}
        >
          {t('selectProductsToPay')}
        </Cta>

        <Cta spaceBetween onClick={() => onButtonClick('arbitrary')}>
          {t('chooseAmount')}
        </Cta>

        <Cta spaceBetween onClick={() => onButtonClick('all')}>
          {t('payAll')}
          <span>{formatPrice(order.leftToPay)}</span>
        </Cta>
      </Component>
    </>
  );
};
