import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/Modal';
import { Cta } from 'theme/globals';
import { HeaderTitle, BodyBox, LiTitle } from './styles';

type ModalCguProps = {
  isOpen: boolean;
  toggleVisibility: () => void;
};

export const ModalCgu = ({ isOpen, toggleVisibility }: ModalCguProps) => {
  const { t } = useTranslation();

  return (
    <Modal data-cy="cgu-modal" isOpen={isOpen} closeModal={toggleVisibility} hideModal>
      <HeaderTitle>{t('cguTitle')}</HeaderTitle>
      <BodyBox>
        <p>{t('cguBody.paragraph1')}</p>
        <p>{t('cguBody.paragraph2')}</p>
        <p>{t('cguBody.paragraph3')}</p>
        <p>{t('cguBody.paragraph4')}</p>

        <ol>
          <LiTitle>{t('cguBody.ol1.title')}</LiTitle>
          <p>{t('cguBody.ol1.description')}</p>
          <ul>
            <li>
              <p>{t('cguBody.ol1.ul1')}</p>
            </li>
            <li>
              <p>{t('cguBody.ol1.ul2')}</p>
            </li>
            <li>
              <p>{t('cguBody.ol1.ul3')}</p>
            </li>
            <li>
              <p>{t('cguBody.ol1.ul4')}</p>
            </li>
            <li>
              <p>{t('cguBody.ol1.ul5')}</p>
            </li>
            <li>
              <p>{t('cguBody.ol1.ul6')}</p>
            </li>
          </ul>
          <p>{t('cguBody.ol1.paragraph1')}</p>
          <p>{t('cguBody.ol1.paragraph2')}</p>
          <p>{t('cguBody.ol1.paragraph3')}</p>
          <p>{t('cguBody.ol1.paragraph4')}</p>
          <p>{t('cguBody.ol1.paragraph5')}</p>
          <LiTitle>{t('cguBody.ol2.title')}</LiTitle>
          <p>{t('cguBody.ol2.paragraph1')}</p>
          <p>{t('cguBody.ol2.paragraph2')}</p>
          <LiTitle>{t('cguBody.ol3.title')}</LiTitle>
          <p>{t('cguBody.ol3.paragraph1')}</p>
          <p>{t('cguBody.ol3.paragraph2')}</p>
          <p>{t('cguBody.ol3.paragraph3')}</p>
          <p>{t('cguBody.ol3.paragraph4')}</p>
          <LiTitle>{t('cguBody.ol4.title')}</LiTitle>
          <p>{t('cguBody.ol4.paragraph1')}</p>
          <p>{t('cguBody.ol4.paragraph2')}</p>
          <p>{t('cguBody.ol4.paragraph3')}</p>
          <LiTitle>{t('cguBody.ol5.title')}</LiTitle>
          <p>{t('cguBody.ol5.paragraph1')}</p>
          <LiTitle>{t('cguBody.ol6.title')}</LiTitle>
          <p>{t('cguBody.ol6.paragraph1')}</p>
          <LiTitle>{t('cguBody.ol7.title')}</LiTitle>
          <p>{t('cguBody.ol7.paragraph1')}</p>
          <p>{t('cguBody.ol7.paragraph2')}</p>
        </ol>
      </BodyBox>

      <Cta id="okay" onClick={toggleVisibility}>
        {t('okay')}
      </Cta>
    </Modal>
  );
};
