import { createGlobalStyle } from 'styled-components';
import { Theme } from 'utils/dto';
import { DefaultColor } from './colors';
import { DefaultFont } from './fonts';

type ThemeStylesProps = {
  theme: Theme;
};

export const ThemeStyles = createGlobalStyle<ThemeStylesProps>`
:root {
    /* FONTS: */
    --primaryFont: ${(props) => props.theme.primaryFont || DefaultFont.Primary};
    --secondaryFont: ${(props) => props.theme.secondaryFont || DefaultFont.Secondary};

    /* COLORS: */
    --primary: ${(props) => props.theme.primaryColor || DefaultColor.Primary};
    --primaryLighter: ${(props) => props.theme.primaryLighterColor || DefaultColor.PrimaryLighter};
    --secondary: ${(props) => props.theme.secondaryColor || DefaultColor.Secondary};
    --headerColor: ${(props) => props.theme.headerColor || DefaultColor.HeaderColor};
    --headerIconsColor: ${(props) =>
      props.theme.headerIconsColor || DefaultColor.HeaderIconsColors};

    --primaryFontColor: ${(props) => props.theme.primaryFontColor || DefaultColor.Font};
    --primaryFontLighterColor: ${(props) =>
      props.theme.primaryFontLighterColor || DefaultColor.FontLighter};
}
`;
