import styled from 'styled-components';
import { Color, Font, FontSize, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const HeaderTitle = styled.div`
  font-family: ${Font.Secondary};
  font-size: ${FontSize.Regular};
  font-weight: ${FontWeight.Bold};
  color: ${Color.Secondary};
  text-align: center;
`;

export const BodyBox = styled.div`
  font-size: ${FontSize.Big};
  line-height: 24px;
  text-align: left;
  margin: ${pxToRem(16)} 0 ${pxToRem(24)};

  p {
    padding-bottom: ${pxToRem(8)};
    font-size: ${FontSize.Small};
  }

  ul {
    margin-left: ${pxToRem(32)};
    padding-bottom: ${pxToRem(8)};
  }
`;

export const LiTitle = styled.li`
  margin-left: ${pxToRem(16)};
  text-transform: uppercase;
  font-size: ${FontSize.Regular};
  font-weight: ${FontWeight.Bold};
  color: ${Color.Secondary};
  margin-top: ${pxToRem(24)};
  margin-bottom: ${pxToRem(16)};
`;
