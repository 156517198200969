import React, { ReactNode, createContext, Dispatch, SetStateAction, useState } from 'react';
import { PaymentStep } from 'pages/Payment/content';
import { Order, OrderedProduct } from 'utils/dto';

export type Type = {
  currentStepState: [PaymentStep, Dispatch<SetStateAction<PaymentStep>>];
  customerEmailState: [string, Dispatch<SetStateAction<string>>];
  orderState: [Order | null, Dispatch<SetStateAction<Order | null>>];
  arbitraryAmountState: [number | undefined, Dispatch<SetStateAction<number | undefined>>];
  selectedProductsState: [OrderedProduct[], Dispatch<SetStateAction<OrderedProduct[]>>];
};

const defaultValue: Type = {
  currentStepState: [PaymentStep.Method, () => null],
  customerEmailState: ['', () => null],
  orderState: [null, () => null],
  arbitraryAmountState: [undefined, () => null],
  selectedProductsState: [[], () => null],
};

export const PaymentContext = createContext<Type>(defaultValue);

export const PaymentContextProvider = ({ children }: { children: ReactNode }) => {
  const currentStepState = useState(defaultValue.currentStepState[0]);
  const customerEmailState = useState(defaultValue.customerEmailState[0]);
  const orderState = useState(defaultValue.orderState[0]);
  const arbitraryAmountState = useState<number>();

  const selectedProductsState = useState(defaultValue.selectedProductsState[0]);

  const value: Type = {
    currentStepState,
    customerEmailState,
    orderState,
    arbitraryAmountState,
    selectedProductsState,
  };

  return <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>;
};
