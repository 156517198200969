import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { ModalCgu } from 'components/ModalCgu';
import { usePaymentContext } from 'hooks/usePaymentContext';
import { PaymentStep } from 'pages/Payment/content';
import { StepButton } from 'pages/Payment/styles';
import { emailSchema } from 'utils/validation';
import { Container, TextInputContainer, CheckboxInputContainer, TermsLink } from './styles';

type FormType = { email: string; cgu: boolean };

export const ContactStep = () => {
  const [displayCguModal, setDisplayCguModal] = useState(false);
  const { setCurrentStep, setCustomerEmail } = usePaymentContext();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormType>({ resolver: yupResolver(emailSchema) });

  const onSubmit = (data: FormType) => {
    setCustomerEmail(data.email);
    setCurrentStep(PaymentStep.MeanChoice);
  };

  return (
    <>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <TextInputContainer htmlFor="email" error={!!errors.email}>
          <input
            data-cy="input"
            type="text"
            placeholder={t('fieldsPlaceholder.email')}
            autoComplete="off"
            {...register('email')}
          />
          <span data-cy="span">{t('inputEmail')}</span>
        </TextInputContainer>

        <CheckboxInputContainer htmlFor="cgu" error={!!errors.cgu}>
          <input type="checkbox" data-cy="cgu" {...register('cgu')} required />
          <TermsLink
            role="link"
            tabIndex={0}
            onClick={() => setDisplayCguModal(true)}
            onKeyPress={(event) => event.key === 'Enter' && setDisplayCguModal(true)}
          >
            <p id="terms">{t('acceptTerms')}</p>
          </TermsLink>
        </CheckboxInputContainer>

        <StepButton id="validate-button" type="submit" center>
          {t('validate')}
        </StepButton>
      </Container>

      <ModalCgu
        isOpen={displayCguModal}
        toggleVisibility={() => setDisplayCguModal(!displayCguModal)}
      />
    </>
  );
};
