import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, getOrderedProductsPrice } from 'utils/price';
import { StepButton } from 'pages/Payment/styles';

import { OrderedProductList } from 'components/Payment/OrderedProductList';

import { IncrementButton } from 'theme/globals';
import { usePaymentContext } from 'hooks/usePaymentContext';
import { PaymentStep } from 'pages/Payment/content';

import { AllPrice, LeftToPay } from './styles';

export const SelectProductStep = () => {
  const { order, setSelectedProducts, setCurrentStep, selectedProducts } = usePaymentContext();

  const { t } = useTranslation();

  if (!order) return null;

  const isButtonDisabled = selectedProducts.length < 1;
  const areAllProductsSelected =
    selectedProducts.length === order.orderedProducts.filter((product) => !product.isPaid).length;

  return (
    <>
      <AllPrice
        data-cy="select-all"
        type="button"
        onClick={() =>
          areAllProductsSelected
            ? setSelectedProducts([])
            : setSelectedProducts(order.orderedProducts.filter((product) => !product.isPaid))
        }
      >
        <p>{t('selectAll')}</p>
        <LeftToPay>{formatPrice(order.leftToPay)}</LeftToPay>

        <IncrementButton> {areAllProductsSelected ? '-' : '+'}</IncrementButton>
      </AllPrice>

      <OrderedProductList paidProducts="hidden" addButton />

      <StepButton
        id="pay-button"
        disabled={isButtonDisabled}
        onClick={() => setCurrentStep(PaymentStep.Contact)}
      >
        {t('pay')}
        {!isButtonDisabled && (
          <span> {formatPrice(getOrderedProductsPrice(selectedProducts))}</span>
        )}
      </StepButton>
    </>
  );
};
