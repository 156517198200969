import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ModalContext, ModalType } from 'contexts/Modal';
import { usePaymentContext } from 'hooks/usePaymentContext';
import { PaymentStep } from 'pages/Payment/content';
import { StepButton } from 'pages/Payment/styles';
import { useApi, Method } from 'services/api';
import { Container } from './styles';

export const ErrorStep = () => {
  const { setCurrentStep } = usePaymentContext();
  const { toggleModal } = useContext(ModalContext);

  const { t } = useTranslation();
  const { shopId, tableNumber } = useParams();

  const { sendRequest, resetError } = useApi(
    `shop/${shopId}/table/${tableNumber}/help`,
    Method.POST
  );

  const handleHelpClick = async () => {
    toggleModal(ModalType.NeedHelp);
    resetError();
    sendRequest();
  };

  return (
    <Container>
      <p>{t('errorMessage')}</p>
      <StepButton onClick={() => setCurrentStep(PaymentStep.CreditCard)} type="button" center>
        {t('backToPayment')}
      </StepButton>
      <StepButton onClick={handleHelpClick} type="button" center>
        {t('callServeur')}
      </StepButton>
    </Container>
  );
};
