import styled from 'styled-components';
import { Color, Font, FontSize, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const InputBox = styled.div`
  margin: ${pxToRem(30)} ${pxToRem(16)};
  padding: ${pxToRem(16)};
  border: 1px solid ${Color.Secondary};
  border-radius: 5px;

  display: flex;
  align-items: center;

  font-family: ${Font.Secondary};
  font-size: ${FontSize.Big};
  font-weight: ${FontWeight.Light};

  h1 {
    font-size: ${FontSize.Big};
    font-weight: ${FontWeight.Bold};
  }

  input {
    all: unset;
    border-bottom: 1px solid ${Color.Secondary};
    padding: ${pxToRem(4)};
    margin: 0 ${pxToRem(4)} 0 auto;
    width: ${pxToRem(60)};

    font-size: ${FontSize.Big};
    font-weight: ${FontWeight.Light};
  }
`;

type Props = {
  isTotalLeft?: boolean;
};

export const TotalBox = styled.div<Props>`
  border-bottom: 2px solid ${Color.Secondary};
  margin: 0 ${(props) => (props.isTotalLeft ? 0 : pxToRem(16))};
  padding-bottom: ${(props) => (props.isTotalLeft ? pxToRem(8) : pxToRem(16))};

  display: flex;
  justify-content: space-between;
  text-transform: uppercase;

  p {
    color: ${Color.FontLighter};
    font-family: ${Font.Secondary};
    font-size: ${FontSize.Regular};
    font-weight: ${FontWeight.Regular};
  }
`;

export const YourPaymentBox = styled.div`
  margin: ${pxToRem(16)};
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;

  p {
    color: ${Color.FontLighter};
    font-family: ${Font.Secondary};
    font-size: ${FontSize.Big};
    font-weight: ${FontWeight.Bold};
  }
`;
