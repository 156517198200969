import { createGlobalStyle } from 'styled-components';
import { pxToRem } from 'utils/style-utils';
import { Color, FontWeight, Font } from '.';
import { BreakPoints } from './breakPoints';

export const GlobalStyle = createGlobalStyle`
html {
  font-size: 8px;
  width: 100%;
}

* {
  padding: 0;
  margin: 0;
  font-family: ${Font.Primary};
  color: ${Color.Font};
  font-weight: ${FontWeight.Regular};
}

p{ 
  line-height: 150%;
}

:focus {
  outline: none;
}

body {
  background: ${Color.UtilityBright};
  width: 100%;
}

button {
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: start;    
  display: block;
}

button:disabled {
  opacity: 0.2;
  pointer-events: none;
  background-color: ${Color.Disabled};
}

@media screen and (min-width: ${BreakPoints.Tablet}) {
  body{
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: ${pxToRem(32)};
  }

  #root{
    grid-column: 2 / span 8 ;
  }
}

@media screen and (min-width: ${BreakPoints.Desktop}) {
  html{
    font-size: 9px;
  }
}

@media screen and (min-width: ${BreakPoints.DesktopLarge}) {
  body{
    grid-template-columns: repeat(12, 1fr);
  }

  #root{
    grid-column: 3 / span 8 ;
  }
}
`;
