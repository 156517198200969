import styled from 'styled-components';
import { CSSProperties } from 'react';
import { pxToRem } from 'utils/style-utils';
import { Color, Font, FontSize, FontWeight } from 'theme';

export const modalStyles = {
  content: { padding: 0 },
  header: { padding: `${pxToRem(24)} ${pxToRem(24)} 0 ${pxToRem(24)}` },
};

export const DataBox = styled.div`
  padding: ${pxToRem(24)} ${pxToRem(24)} ${pxToRem(8)} ${pxToRem(24)};
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};
  margin-bottom: ${pxToRem(16)};

  h2 {
    font-size: ${FontSize.Big};
    font-weight: ${FontWeight.Bold};
    font-family: ${Font.Secondary};
  }

  p {
    font-size: ${FontSize.Regular};
  }
`;

export const getBackgroundColor = (index: number): CSSProperties => ({
  backgroundColor: index % 2 === 0 ? Color.PrimaryLighter : Color.UtilityBright,
});

export const DayRow = styled.div`
  padding: ${pxToRem(8)} ${pxToRem(24)};
  font-size: ${FontSize.Regular};
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
`;

export const SocialNetworksBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: ${pxToRem(20)} 0 ${pxToRem(24)} 0;
`;

export const CommentLink = styled.a`
  border-radius: ${pxToRem(5)};
  border: 2px solid ${Color.Primary};

  padding: ${pxToRem(12)} 0;
  margin: ${pxToRem(20)} 5vw 0;
  display: block;

  color: ${Color.Primary};
  font-family: ${Font.Secondary};
  font-size: ${FontSize.Big};
  font-weight: ${FontWeight.Bold};
  text-decoration: none;
  text-align: center;
`;

export const SocialNetworkIconStyle = {
  width: pxToRem(40),
  height: pxToRem(40),
} as CSSProperties;
