import React from 'react';
import { useTranslation } from 'react-i18next';

import CbLogo from 'assets/CbLogo.png';
import VisaLogo from 'assets/VisaLogo.png';
import MastercardLogo from 'assets/MastercardLogo.png';
import { GoogleApplePayStep } from 'components/Payment/GoogleApplePayButton';
import { usePaymentContext } from 'hooks/usePaymentContext';
import { PaymentStep } from 'pages/Payment/content';
import {
  Step,
  // Container,
  // IncrementTip,
  PayByCreditCard,
  CreditCardIcon,
} from './styles';

// no Tip in v1

export const MeanChoiceStep = () => {
  // const [tip, setTip] = useState(200);

  const { setCurrentStep } = usePaymentContext();

  const { t } = useTranslation();

  return (
    <Step>
      {/* <Container>
        <p>Laisser un pourboire ?</p>
        <IncrementTip>
          <button type="button" onClick={() => setTip((prev) => prev - 100)}>
            -
          </button>
          <span>{formatPrice(tip)}</span>
          <button type="button" onClick={() => setTip((prev) => prev + 100)}>
            +
          </button>
        </IncrementTip>
      </Container> */}

      <p>{t('selectPaymentMethod')}</p>

      <PayByCreditCard id="credit-card" onClick={() => setCurrentStep(PaymentStep.CreditCard)}>
        {t('creditCard')}
        <CreditCardIcon src={CbLogo} alt="" />
        <CreditCardIcon src={VisaLogo} alt="" />
        <CreditCardIcon src={MastercardLogo} alt="" />
      </PayByCreditCard>

      <GoogleApplePayStep />
    </Step>
  );
};
