import { css, keyframes } from 'styled-components';

export const hoverTransition = css`
  transition: all 150ms ease-out 150ms;
`;

export const fromRightAnimation = keyframes`
  from {
    transform: translateX(30px);
  }
`;

export const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
`;

export const shake = keyframes`
10%, 90% {
  transform: translate3d(-1px, 0, 0);
}
20%, 80% {
  transform: translate3d(2px, 0, 0);
}
30%, 50%, 70% {
  transform: translate3d(-4px, 0, 0);
}
40%, 60% {
  transform: translate3d(4px, 0, 0);
}
`;
