import styled from 'styled-components';
import { Color, Font, FontSize, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const Component = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(24)};
`;

export const Header = styled.div`
  border-bottom: 2px solid ${Color.Secondary};
  margin-bottom: ${pxToRem(24)};
  padding: ${pxToRem(8)} 0;
  display: flex;

  color: ${Color.FontLighter};
  font-family: ${Font.Secondary};
  font-size: ${FontSize.Regular};
  font-weight: ${FontWeight.Regular};
  text-transform: uppercase;

  div {
    margin-left: auto;
    color: ${Color.FontLighter};
    font-family: ${Font.Secondary};
    font-size: ${FontSize.Regular};
    font-weight: ${FontWeight.Regular};
    text-transform: uppercase;
  }
`;
