import styled from 'styled-components';
import { Color, Font, FontSize, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const Heading = styled.div`
  padding: ${pxToRem(24)} ${pxToRem(24)} ${pxToRem(16)};
  display: flex;
  align-items: center;

  svg {
    path {
      fill: ${Color.Secondary};
    }
  }

  h1 {
    font-size: ${FontSize.Title};
    font-family: ${Font.Secondary};
    font-weight: ${FontWeight.Light};
    color: ${Color.FontLighter};
    margin-left: ${pxToRem(30)};
  }

  span {
    color: ${Color.Secondary};
    font-size: ${FontSize.Regular};
    margin-left: auto;

    b {
      color: ${Color.Secondary};
      font-weight: ${FontWeight.Bold};
    }
  }
`;

type ProgressBarProps = {
  progression: string;
};

export const ProgressBar = styled.div<ProgressBarProps>`
  background: ${Color.PrimaryLighter};
  height: ${pxToRem(6)};
  width: 100%;
  margin-bottom: ${pxToRem(24)};
  position: relative;

  ::after {
    content: '';
    background: ${Color.Primary};
    position: absolute;
    height: 100%;
    width: ${(props) => props.progression};
    transition: width 200ms ease-in-out;
  }
`;

export const StepsContainer = styled.div`
  margin: 0 ${pxToRem(24)};
  padding-bottom: ${pxToRem(136)}; // StepButton safe area (40 + 40 + 18 + 18 + 20)
`;

export const Error = styled.p`
  color: ${Color.Error};
  font-size: ${FontSize.Regular};
  text-align: center;
`;

export const StepButton = styled.button<{ center?: boolean }>`
  background: ${Color.Primary};
  border-radius: ${pxToRem(5)};

  margin: ${pxToRem(40)} auto 0;
  padding: ${pxToRem(18)};
  width: 80vw;
  height: ${pxToRem(60)};

  ${(props) =>
    props.center
      ? `text-align: center;`
      : `
    display: flex;
    justify-content: space-between;
    align-items: center;
    `}

  color: ${Color.UtilityBright};
  font-family: ${Font.Secondary};
  font-size: ${FontSize.Big};
  font-weight: ${FontWeight.Bold};

  span {
    font-family: ${Font.Secondary};
    color: ${Color.UtilityBright};
    font-weight: ${FontWeight.Light};
    font-size: ${FontSize.Title};
  }

  bottom: ${pxToRem(40)};
`;
