import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Color, Font, FontSize, FontWeight } from 'theme';
import { pxToRem, withOpacity } from 'utils/style-utils';
import { BreakPoints } from 'theme/breakPoints';

export const Background = styled(motion.div)`
  background: ${withOpacity(Color.UtilityDark, 0.7)};

  position: fixed;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  position: relative;
  background-color: ${Color.UtilityBright};
  border-radius: 20px;
  max-height: 90vh;
  width: 100%;
  overflow-y: auto;
  padding: ${pxToRem(24)};
  margin: ${pxToRem(16)};

  @media screen and (min-width: ${BreakPoints.Mobile}) {
    width: 50%;
  }

  @media screen and (min-width: ${BreakPoints.Tablet}) {
    width: 40%;
  }

  @media screen and (min-width: ${BreakPoints.Desktop}) {
    width: 30%;
  }

  @media screen and (min-width: ${BreakPoints.DesktopLarge}) {
    width: 20%;
  }
`;

type HeaderProps = {
  isFixed?: boolean;
};

export const HeaderBox = styled.div<HeaderProps>`
  position: ${(props) => (props.isFixed ? 'sticky' : 'relative')};
  top: ${(props) => props.isFixed && `0`};
  padding: ${(props) => props.isFixed && `${pxToRem(24)} 0 ${pxToRem(16)} 0`};
  background: ${(props) =>
    props.isFixed &&
    `linear-gradient(
    ${withOpacity(Color.UtilityBright, 1)} 0%,
    ${withOpacity(Color.UtilityBright, 1)} 80%,
    ${withOpacity(Color.UtilityBright, 0)} 100%
  )`};
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${pxToRem(16)};
  gap: ${pxToRem(32)};

  h1 {
    width: 100%;
    font-family: ${Font.Secondary};
    font-size: ${FontSize.Big};
    font-weight: ${FontWeight.Light};
  }
`;
