/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

const apiUrl = `${process.env.REACT_APP_API_URI}frontshop/`;

type ResponseType = {
  data: any;
  status: number | undefined;
};

export enum Method {
  GET = 'GET',
  POST = 'POST',
}

export const useApi = (url: string, method: Method) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [response, setResponse] = useState<ResponseType>({
    status: 0,
    data: undefined,
  });

  const resetError = () => setError(undefined);

  const sendRequest = async (body?: any) => {
    setIsLoading(true);
    resetError();

    let data: any;
    let status = 0;

    const requestOptions: RequestInit = {
      method,
      body: JSON.stringify(body),
    };

    await fetch(`${apiUrl}${url}`, {
      ...requestOptions,
      headers: new Headers({
        'Content-Type': 'application/json',
        // we can add token manipulations here
      }),
    })
      .then((res) => {
        status = res.status;
        return res.json();
      })
      .then((result) => {
        data = result;
      })
      .catch((exception: Error) => {
        setError(exception);
      });

    setIsLoading(false);
    setResponse({ status, data });
  };

  useEffect(() => {
    if (method === Method.GET) sendRequest();
  }, []);

  return {
    sendRequest,
    isLoading,
    status: response.status,
    data: response.data,
    error,
    resetError,
  };
};
