import styled from 'styled-components';
import { Color, Font, FontSize, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const Component = styled.div`
  h1 {
    font-family: ${Font.Secondary};
    font-size: ${FontSize.Big};
    font-weight: ${FontWeight.Light};
    margin: 0 ${pxToRem(15)} ${pxToRem(16)};
  }
`;

export const ButtonsBox = styled.div`
  margin-bottom: ${pxToRem(32)};
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: ${pxToRem(40)};
    width: ${pxToRem(40)};
    background: ${Color.Secondary};
    border-radius: 50%;
    text-align: center;
    color: ${Color.UtilityBright};

    font-family: ${Font.Primary};
    font-size: ${FontSize.Title};
    font-weight: ${FontWeight.Light};
  }

  h2 {
    font-family: ${Font.Secondary};
    font-size: ${FontSize.Title};
    font-weight: ${FontWeight.Light};
    margin: 0 ${pxToRem(15)};
  }
`;
