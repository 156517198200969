import styled from 'styled-components';
import { Color, Font, FontSize } from 'theme';
import { pxToRem, withOpacity } from 'utils/style-utils';

export const AllPrice = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${pxToRem(8)} ${pxToRem(8)} 0;
  border-bottom: solid 2px ${Color.Secondary};

  font-family: ${Font.Primary};
  font-size: ${FontSize.Regular};
  text-transform: uppercase;
  color: ${withOpacity(Color.UtilityDark, 0.7)};
  text-transform: uppercase;
`;

export const LeftToPay = styled.span`
  margin-left: ${pxToRem(8)};
  text-align: right;
  min-width: ${pxToRem(80)};
  font-family: ${Font.Secondary};
`;
