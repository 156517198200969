import styled from 'styled-components';
import { Color, Font, FontSize, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const Step = styled.div`
  font-size: ${FontSize.Big};

  p {
    margin-bottom: ${pxToRem(16)};
    font-family: ${Font.Secondary};
    font-weight: ${FontWeight.Light};
    margin: 0 ${pxToRem(13)} ${pxToRem(16)};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: ${pxToRem(24)};

  border: 2px solid ${Color.Secondary};
  border-right: none;
  border-left: none;
`;

export const IncrementTip = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${Color.PrimaryLighter};
    border-radius: 50%;
    height: ${pxToRem(40)};
    width: ${pxToRem(40)};
  }

  span {
    font-family: ${Font.Secondary};
    font-weight: ${FontWeight.Light};
  }
`;

export const PayByCreditCard = styled.button`
  border-radius: ${pxToRem(5)};
  margin: ${pxToRem(32)} auto 0;
  padding: ${pxToRem(18)};
  width: 80vw;
  height: ${pxToRem(60)};
  color: ${Color.Primary};
  border: 2px solid ${Color.Primary};
  font-family: ${Font.Secondary};
  font-size: ${FontSize.Big};
  font-weight: ${FontWeight.Bold};

  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const CreditCardIcon = styled.img`
  width: ${pxToRem(32)};
  min-width: ${pxToRem(32)};
  height: auto;
`;
