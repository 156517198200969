import styled from 'styled-components';
import { Color, DefaultColor, Font, FontSize, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';
import { BorderType } from './content';

type ProductType = {
  bottomBorder: BorderType;
  isSelected: boolean;
  isClickable: boolean;
  isMuted: boolean;
};

const getBottomBorder = (borderType: BorderType) => {
  switch (borderType) {
    case BorderType.None:
      return '0';
    case BorderType.Secondary:
      return `1px solid ${Color.Secondary}`;
    default:
      return `1px solid ${Color.PrimaryLighter}`;
  }
};

export const Product = styled.button<ProductType>`
  width: 100%;
  border-bottom: ${(props) => getBottomBorder(props.bottomBorder)};
  padding: ${pxToRem(8)} ${pxToRem(8)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: ${(props) => (props.isClickable ? 'auto' : 'none')};
  background: ${(props) => (props.isSelected ? DefaultColor.PrimaryLighter : 'transparent')};
  opacity: ${(props) => (props.isMuted ? 0.3 : 1)};
`;

export const ProductInformation = styled.div`
  color: ${Color.Primary};
  font-family: ${Font.Secondary};
  font-size: ${FontSize.Regular};
  font-weight: ${FontWeight.Bold};
`;

export const Price = styled.div`
  font-family: ${Font.Secondary};
  font-size: ${FontSize.Regular};
  color: ${Color.UtilityDark};
  font-weight: ${FontWeight.Light};
  white-space: nowrap;

  display: flex;
  align-items: center;
`;

export const Quantity = styled.span`
  padding: 0 ${pxToRem(8)};

  color: ${Color.Secondary};
  font-family: ${Font.Secondary};
  font-size: ${FontSize.Regular};
  font-weight: ${FontWeight.Bold};
`;

export const OptionBox = styled.ul`
  margin: 0 ${pxToRem(16)};
  padding-top: ${pxToRem(4)};
`;

export const Option = styled.li`
  font-size: ${FontSize.Small};
  color: ${Color.FontLighter};
  margin-top: ${pxToRem(4)};
`;
