export enum DefaultColor {
  Primary = '#20254F',
  PrimaryLighter = '#EDEDED',
  Secondary = '#A5611A',
  Font = '#20244E',
  FontLighter = '#A06732',
  HeaderColor = '#20254F',
  HeaderIconsColors = '#FAFAFA',
}

export enum Color {
  Primary = 'var(--primary)',
  PrimaryLighter = 'var(--primaryLighter)',
  Secondary = 'var(--secondary)',

  UtilityDark = '#1D232C',
  UtilityBright = '#FFFFFF',

  Font = 'var(--primaryFontColor)',
  FontLighter = 'var(--primaryFontLighterColor)',
  Background = '#F9F9F9',
  Error = '#DD102D',
  Disabled = '#AFAFAF',

  HeaderColor = 'var(--headerColor)',
  HeaderIconsColors = 'var(--headerIconsColor)',
}
