import { OrderedProduct } from './dto';

/**
 * Gather same products in one product with quantity sum
 * But we lose the orderedProduct Id
 */
export const mergeOrderedProducts = (products: OrderedProduct[]): OrderedProduct[] =>
  products.reduce((accumulator: OrderedProduct[], currentProduct: OrderedProduct) => {
    const isCurrentProductEqual = (product: OrderedProduct) =>
      JSON.stringify({
        productId: product.productId,
        orderedOptions: product.orderedOptions.sort(),
        isPaid: product.isPaid,
        quantity: 0,
        id: '',
      }) ===
      JSON.stringify({
        productId: currentProduct.productId,
        orderedOptions: currentProduct.orderedOptions.sort(),
        isPaid: currentProduct.isPaid,
        quantity: 0,
        id: '',
      });

    const mergedQuantity = products.filter((product) => isCurrentProductEqual(product)).length;

    if (!accumulator.some((product) => isCurrentProductEqual(product))) {
      const product: OrderedProduct = {
        ...currentProduct,
        quantity: mergedQuantity,
      };

      accumulator.push(product);
    }

    return accumulator;
  }, []);
