import styled from 'styled-components';
import { Font, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: ${pxToRem(14)};
    font-family: ${Font.Primary};
    font-weight: ${FontWeight.Regular};
    text-align: center;
  }
`;
