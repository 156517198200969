import React, { useRef, useCallback, useEffect, MouseEvent, ReactNode } from 'react';
import { AnimatePresence } from 'framer-motion';
import { CloseCross } from 'components/CloseCross';
import { backgroundAnimProps, ModalStyles } from './content';
import { Background, ModalContainer, HeaderBox } from './styles';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  children: ReactNode;
  title?: string;
  fixedHeader?: boolean;
  additionalStyles?: ModalStyles;
  hideModal?: boolean;
};

export function Modal({
  isOpen,
  closeModal,
  children,
  title,
  fixedHeader,
  additionalStyles,
  hideModal,
}: Props) {
  const modalRef = useRef<HTMLDivElement>(null);

  const onBgClick = (e: MouseEvent) => modalRef.current === e.target && closeModal();

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'visible';
  }, [isOpen]);

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => e.key === 'Escape' && isOpen && closeModal(),
    [isOpen, closeModal]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress);
    return () => document.removeEventListener('keydown', onKeyPress);
  }, [onKeyPress]);

  return (
    <AnimatePresence>
      {isOpen && (
        <Background ref={modalRef} onClick={onBgClick} {...backgroundAnimProps}>
          <ModalContainer style={additionalStyles?.content}>
            {!hideModal && (
              <HeaderBox isFixed={fixedHeader} style={additionalStyles?.header}>
                <h1>{title}</h1>
                <CloseCross onClick={closeModal} />
              </HeaderBox>
            )}

            {children}
          </ModalContainer>
        </Background>
      )}
    </AnimatePresence>
  );
}

Modal.defaultProps = {
  title: '',
  additionalStyles: undefined,
  fixedHeader: false,
  hideModal: false,
};
