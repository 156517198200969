import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PaymentRequestButtonElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { ShopDataContext } from 'contexts/ShopData';
import { usePaymentContext } from 'hooks/usePaymentContext';
import { Method, useApi } from 'services/api';
import { PaymentStep } from 'pages/Payment/content';

// https://www.youtube.com/watch?v=bMCsJfJyQKA

// TODO: test when feature is back
export const GoogleApplePayStep = () => {
  const { setCurrentStep, customerEmail, selectedProducts, order, arbitraryAmount } =
    usePaymentContext();

  const { shopInformation } = useContext(ShopDataContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [paymentRequest, setPaymentRequest] = useState<any>(null);

  const stripe = useStripe();
  const elements = useElements();

  const { shopId } = useParams();

  const isArbitraryPayment = arbitraryAmount !== undefined;

  const {
    data: clientSecret,
    status,
    sendRequest,
    // error, TODO: handle error here
  } = useApi(
    isArbitraryPayment
      ? `shop/${shopId}/order/${order?.id}/arbitrary-payment`
      : `shop/${shopId}/order/${order?.id}`,
    Method.POST
  );

  useEffect(() => {
    if (!stripe || !elements || !order) return;

    const pr = stripe.paymentRequest({
      country: 'FR',
      currency: 'eur',
      total: {
        label: `${shopInformation.name} bill`,
        amount: order.leftToPay,
      },
    });

    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
      }
    });

    pr.on('paymentmethod', async (e) => {
      // create paymentIntent on the server
      await sendRequest(
        isArbitraryPayment
          ? {
              email: customerEmail,
              paymentAmount: arbitraryAmount,
            }
          : {
              email: customerEmail,
              productsToPayId: selectedProducts.map((product) => product.id),
            }
      );

      // confirm the payment intent on the client
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: e.paymentMethod?.id },
        { handleActions: false }
      );

      if (error) {
        e.complete('fail');
        setCurrentStep(PaymentStep.Error);
        return;
      }

      e.complete('success');
      if (paymentIntent?.status === 'requires_action') {
        stripe.confirmCardPayment(clientSecret);
      }

      setCurrentStep(PaymentStep.Complete);
    });
  }, []);

  if (!order) return null;

  return paymentRequest && status === 202 ? (
    <PaymentRequestButtonElement options={{ paymentRequest }} />
  ) : null;
};
