import { CSSProperties } from 'react';
import { HTMLMotionProps } from 'framer-motion';

const TRANSITION_DURATION = 0.2;

export type ModalStyles = { header?: CSSProperties; content?: CSSProperties };

export const backgroundAnimProps: HTMLMotionProps<'div'> = {
  variants: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: TRANSITION_DURATION },
    },
    exit: {
      opacity: 0,
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'exit',
};
